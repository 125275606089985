import { Carousel, useCarousel } from "nuka-carousel";

import { isEmpty } from "lodash";
import { s3ImageUrl } from "../../utils/FileUtils";

export const CustomDots = () => {
  const { totalPages, currentPage, goToPage } = useCarousel();
  return (
    <div className="-pt-4 flex w-full flex-row items-center justify-center gap-1">
      {[...Array(totalPages)].map((_, index) => (
        <button
          key={index}
          style={{
            backgroundColor: currentPage === index ? "#9F2B2B" : "#ECA6AC",
            width: currentPage === index ? 24 : 6,
          }}
          onClick={() => goToPage(index)}
          className={`hover:bg-green-200 h-[6px] w-[6px] cursor-pointer rounded-full border-none bg-gray-200 p-0 
         `}
        />
      ))}
    </div>
  );
};

export default function BannerSection() {
  const banners = [
    {
      id: 1,
      image: s3ImageUrl("banner1.webp"),
      url: "https://www.facebook.com/share/p/1EGiV4fwGk/",
    },
    {
      id: 2,
      image: s3ImageUrl("banner2.webp"),
      url: "https://www.facebook.com/share/v/18yAPtEc8g/",
    },
    {
      id: 3,
      image: s3ImageUrl("banner3.webp"),
      url: "https://www.facebook.com/share/p/15N8yJuWrD/",
    },
  ];
  return (
    <div className="-mt-2 px-2">
      <Carousel
        scrollDistance="slide"
        showDots
        dots={<CustomDots />}
        autoplay
        wrapMode={"wrap"}
      >
        {banners.map((banner) => (
          <img
            onClick={() => {
              if (!isEmpty(banner.url)) {
                window.open(banner.url, "_blank");
              }
            }}
            src={banner.image}
            className={`rounded-[28px] object-cover p-2`}
            alt="banner"
          />
        ))}
      </Carousel>
    </div>
  );
}
