import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ArrowRight from "assets/icons/arrow-right";
import ChevronRight from "assets/icons/chevron-right";
import { Gap } from "components/gap/Gap";
import { LOCAL_STORAGE_KEYS } from "helpers/constants";
import { isNumber } from "lodash";
import { motion } from "framer-motion";
import { s3ImageUrl } from "utils/FileUtils";

const MAX_TABLE = 20;

const WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const banners = [
    {
      title: "Sắn Cafe",
      image: s3ImageUrl("san_cafe.webp"),
      subTitle: "Đánh thức vị giác",
      description:
        "Đắm mình trong hương vị café đậm đà, hảo hạng, Sắn café đánh thức các giác quan và khơi nguồn sáng tạo trong bạn.",
      buttonTitle: "Order ngay",
      href: "/home",
    },
    {
      title: "Sắn Tarot",
      image: s3ImageUrl("san_tarot.webp"),
      subTitle: "Lắng nghe trực giác",
      description:
        "Vũ trụ luôn có những tín hiệu dành cho bạn. Chọn một lá Tarot và khám phá thông điệp mà bạn cần ngay lúc này.",
      buttonTitle: "Trải nghiệm ngay",
      href: "/tarot",
    },
  ];

  useEffect(() => {
    const table = searchParams.get("tbl") || "";
    if (isNumber(+table) && +table > 0 && +table <= MAX_TABLE) {
      const tableInString = `${table}`.padStart(2, "0");
      localStorage.setItem(LOCAL_STORAGE_KEYS.TABLE_ID, tableInString);
    }
  }, []);

  return (
    <div className="flex h-screen flex-col items-center justify-start bg-brand-shape-4 px-4 pt-12">
      <img src={s3ImageUrl("san-logo.png")} className="h-10 w-10" alt="logo" />
      <h2 className="text-3xl my-4 text-center font-space font-bold">
        Hôm nay bạn đến Sắn để "Thưởng thức" hay "Giải mã"?
      </h2>
      <Gap size={"M"} />
      <div className="flex w-full flex-col justify-center gap-2">
        {banners.map((banner, index) => {
          return (
            <div
              key={index}
              className="flex w-full flex-row items-center justify-center rounded-lg bg-brand-3 p-4"
              onClick={() => {
                navigate(banner.href);
              }}
            >
              <div className="flex w-full flex-col pr-4">
                <span className="my-1 text-[16px] font-bold leading-[20px] text-brand">
                  {banner.title}
                </span>
                <div className="flex w-full flex-col items-start justify-center">
                  <span className="text-[15px] font-bold text-brand-4">
                    {banner.subTitle}
                  </span>
                  <span className="mt-1 text-left text-[11px] text-gray-menu">
                    {banner.description}
                  </span>
                  <Gap size={"S"} />
                  <a
                    href={banner.href}
                    className="flex flex-row items-center justify-center gap-2 font-semibold text-brand"
                  >
                    <span>{banner.buttonTitle}</span>
                    <div className="mt-[1px]">
                      <ArrowRight />
                    </div>
                  </a>
                </div>
              </div>
              <img
                src={banner.image}
                className="h-36 w-36 rounded-full object-cover"
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WelcomePage;
