import "react-spring-bottom-sheet/dist/style.css";

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import React, { useRef, useState } from "react";
import { WithSnackbarProps, withSnackbar } from "hoc/withSnackbar";
import { first, isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "../../store/hook";

import OrderItem from "components/order/OrderItem";
import { Product } from "../../models/Product";
import { ProductItem } from "store/type";
import { ProductItemSource } from "pages/ProductDetailPage/ProductDetailPage";
import ScrollToTop from "components/ScrollToTop";
import { closeBottomSheet } from "../../store/bottomSheetSlice";
import { useCustomization } from "hooks/useCustomization";
import { useNavigate } from "react-router-dom";

export interface ProductDetailBottomSheetProps {}

function ProductDetailBottomSheet(
  props: ProductDetailBottomSheetProps & WithSnackbarProps,
) {
  const { showSnackbar = () => null } = props;
  const { open, product } = useAppSelector((root) => root.bottomSheet);
  const { name, id, tarotCardId, activeIndex, from } =
    (product as Product & { from: ProductItemSource }) || {};
  const currentOrder = useAppSelector((root) => root.order.currentOrder);
  const currentItems = currentOrder?.items || [];
  const navigate = useNavigate();

  const quantity = currentItems.reduce((total, item) => {
    if (item.item_id === id) {
      return item.quantity + total;
    }
    return total;
  }, 0);

  const productVariants = currentItems.filter((item) => item.item_id === id);

  const dispatch = useAppDispatch();
  const sheetRef = useRef<BottomSheetRef>(null);

  const [selectedProduct, setSelectedProduct] = useState<ProductItem>(
    {} as ProductItem,
  );

  const { CustomizationView, UpdateQuantityView } = useCustomization({
    showSnackbar,
    productId: id,
    customizationState: {
      item: selectedProduct,
      from:
        from || quantity === 0
          ? ProductItemSource.NONE
          : ProductItemSource.ORDER,
      tarotCardId,
      activeIndex,
    },
    onConfirmClicked: () => {
      dispatch(closeBottomSheet());
      setSelectedProduct({} as ProductItem);
      if (tarotCardId) {
        navigate(`/orders`);
      }
    },
  });

  const renderCustomization = () => {
    return (
      <div className="flex flex-col gap-2">
        <CustomizationView />
        <UpdateQuantityView />
      </div>
    );
  };

  const renderVariants = () => {
    return (
      <div className="flex flex-col gap-2">
        {productVariants?.map((item: ProductItem, index: number) => (
          <OrderItem
            key={index}
            item={item}
            onClick={() => setSelectedProduct(item)}
            showDivide={index < productVariants.length - 1}
          />
        ))}
      </div>
    );
  };

  const shouldRenderCustomization =
    !isEmpty(selectedProduct) || productVariants.length <= 1;

  return (
    <BottomSheet
      open={open}
      ref={sheetRef}
      maxHeight={(window?.screen?.height - 60) * 0.75}
      expandOnContentDrag
      onDismiss={() => {
        dispatch(closeBottomSheet());
        setSelectedProduct({} as ProductItem);
      }}
    >
      <div
        className={`flex h-fit w-full flex-col items-center ${shouldRenderCustomization ? "p-0" : "p-4"}`}
      >
        <ScrollToTop />
        <div className="flex w-full max-w-screen-md flex-col">
          {shouldRenderCustomization ? renderCustomization() : renderVariants()}
          {/*<VariantSelection />*/}
        </div>
      </div>
    </BottomSheet>
  );
}

export default withSnackbar(ProductDetailBottomSheet);
